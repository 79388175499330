<template>
  <div>
    <Header />
    <div
      class="container mx-auto border border-grey-2 rounded-lg my-10 flex flex-wrap"
    >
      <aside class="md:h-screen top-0 p-4 w-full md:w-fit">
        <form id="form-filter">
          <div class="mb-6">
            <label
              for="select-city"
              class="block mb-2 font-bold text-gray-900 dark:text-gray-400"
              >Select City</label
            >
            <select
              id="select-city"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-xl focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              v-if="listKota.length > 0"
              v-model="idWilayah"
              v-on:change="handleCityChange"
            >
              <option value="">Select City</option>
              <option
                v-for="rowKota in listKota"
                :key="rowKota.nama_wilayah"
                :value="rowKota.id_wilayah"
              >
                {{ rowKota.nama_wilayah }}
              </option>
            </select>
          </div>
          <div class="mb-6" v-if="$route.params.slug == 'landadventure'">
            <label class="block mb-2 font-bold text-gray-900 dark:text-gray-300"
              >Select {{ textLabel }} Type</label
            >
            <div class="flex flex-col gap-2">
              <button
                class="text-grey-2 font-bold py-2 px-4 border border-blue-700 rounded-xl"
              >
                Off Road
              </button>
              <button
                class="text-grey-2 font-bold py-2 px-4 border border-blue-700 rounded-xl"
              >
                On Road
              </button>
            </div>
          </div>
        </form>
      </aside>
      <div class="flex flex-none md:flex-1 flex-col p-4 w-full">
        <div class="flex font-bold px-2">
          <label class="block font-bold align-middle my-auto"
            >Select Package</label
          >
          <div class="flex flex-row gap-2 mx-2">
            <router-link
              :to="{
                name: 'DetailPaket',
                params: {
                  slug: $route.params.slug,
                },
              }"
              class="bg-white hover:bg-primary text-grey-2 hover:text-white font-bold py-1 px-4 border border-grey-2 rounded-xl"
            >
              Day Trip
            </router-link>
            <router-link
              :to="{
                name: 'DetailRental',
                params: {
                  slug: $route.params.slug,
                },
              }"
              class="bg-primary hover:bg-primary text-white font-bold py-1 px-4 border border-primary rounded-xl"
            >
              Rental
            </router-link>
          </div>
        </div>
        <div
          class="hidden sm:flex flex-nowrap gap-4 overflow-y-auto py-4 px-2 w-full overflow-x-auto"
          v-if="listDock.length > 0"
        >
          <div
            class="flex-shrink-0"
            v-for="dock in listDock"
            :key="dock.nama_dermaga"
            @click="changeDock(dock)"
          >
            <CardDock :detail="dock" :selectedDock="formData.selectedDock" />
          </div>
        </div>
        <div class="sm:hidden mb-4 px-5">
          <VueSlickCarousel
            v-bind="settings"
            v-if="listDock.length > 0"
            @afterChange="dockAfterChange"
          >
            <div
              class="flex-shrink-0"
              v-for="dock in listDock"
              :key="dock.nama_dermaga"
              @click="changeDock(dock)"
            >
              <CardDock
                :detail="dock"
                :selectedDock="formData.selectedDock"
                :key="dock.nama_dermaga"
              />
            </div>
            <template #customPaging="page">
              <div class="custom-dot">
                <div class="hidden">{{ page }}</div>
              </div>
            </template>
          </VueSlickCarousel>
        </div>
        <div class="py-4 px-2" v-if="listDock.length > 0">
          <div class="grid grid-cols-9 gap-2 px-2">
            <div class="col-span-9 md:col-span-2 py-2">
              <span class="font-extrabold text-black text-lg">
                Select Time
              </span>
              <div class="block">
                <div class="mt-4">
                  <span class="font-extrabold text-black text-lg"> Start </span>
                  <v-date-picker
                    mode="time"
                    v-model="formData.jam_awal"
                    :is24hr="true"
                    time-format="HH"
                    @input="onInputChange"
                    :min-date="minHour"
                    :max-date="maxHour"
                    ref="jamStartRef"
                  >
                    <template v-slot="{ inputValue, inputEvents }">
                      <div class="mt-1 relative rounded-md w-4/5">
                        <div
                          class="absolute inset-y-0 left-0 px-3 flex items-center pointer-events-none bg-primary border border-grey-2 rounded-xl"
                        >
                          <font-awesome-icon
                            icon="fa-regular fa-clock"
                            :style="{ color: 'white', fontSize: '20px' }"
                          />
                        </div>
                        <input
                          readonly
                          type="text"
                          class="pl-20 shadow appearance-none border border-grey-2 rounded-xl w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline font-extrabold text-xl"
                          :value="inputValue"
                          v-on="inputEvents"
                        />
                      </div>
                    </template>
                  </v-date-picker>
                </div>

                <div class="mt-4">
                  <span class="font-extrabold text-black text-lg mt-4">
                    End
                  </span>
                  <v-date-picker
                    mode="time"
                    v-model="formData.jam_akhir"
                    :is24hr="true"
                    @input="onInputChangeEnd"
                    :min-date="minHour"
                    :max-date="maxHour"
                  >
                    <template v-slot="{ inputValue, inputEvents }">
                      <div class="mt-1 relative rounded-md w-4/5">
                        <div
                          class="absolute inset-y-0 left-0 px-3 flex items-center pointer-events-none bg-primary border border-grey-2 rounded-xl"
                        >
                          <font-awesome-icon
                            icon="fa-regular fa-clock"
                            :style="{ color: 'white', fontSize: '20px' }"
                          />
                        </div>
                        <input
                          readonly
                          type="text"
                          class="pl-20 shadow appearance-none border border-grey-2 rounded-xl w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline font-extrabold text-xl"
                          :value="inputValue"
                          v-on="inputEvents"
                        />
                      </div>
                    </template>
                  </v-date-picker>
                </div>
              </div>
            </div>
            <div class="col-span-9 md:col-span-4 py-2 w-full">
              <span class="font-extrabold text-black text-lg">
                Choose Date
              </span>
              <v-date-picker
                ref="calendar"
                is-expanded
                v-model="formData.bookingDate"
                :attributes="calAttr"
                :disabled-dates="calDisable"
                :minDate="minDate"
                @dayclick="onDayClick"
                @update:from-page="onMonthChange"
                @update:to-page="onMonthChange"
              />
            </div>
            <div
              class="col-span-9 md:col-span-3 py-2 h-full flex flex-col justify-between"
            >
              <span class="font-extrabold text-black text-lg">
                Rental Package
              </span>
              <div
                class="flex flex-col gap-4 overflow-y-auto h-80"
                v-if="this.formData.bookingDate != null"
              >
                <div
                  class="flex flex-row flex-wrap p-2 shadow-lg box-shadow rounded-2xl border border-gray-100"
                  v-for="(paket, paketidx) in listPaket"
                  :key="paketidx"
                >
                  <div class="w-full md:w-1/2 pr-2">
                    <img
                      :src="paket.foto"
                      :alt="paket.nama"
                      class="object-cover rounded-2xl w-full h-28"
                    />
                  </div>
                  <div class="w-full md:w-1/2">
                    <span
                      class="font-extrabold hover:font-thin text-primary text-xl"
                    >
                      {{ paket.nama }} </span
                    ><br />
                    <span class="font-extrabold text-primary text-sm">
                      {{ paket.harga | toCurrency }}/hour
                    </span>
                    <div class="flex">
                      <div
                        class="rounded-2xl font-bold bg-yellow text-center px-2 py-1 grid grid-cols-1 w-20"
                      >
                        <span class="w-full text-xs">Available</span>
                        <span class="w-full">{{ paket.stok }} unit</span>
                      </div>
                      <div class="mx-2 flex flex-row gap-2 items-center">
                        <button
                          type="button"
                          @click="minusQty(paket)"
                          class="text-3xl"
                        >
                          -
                        </button>
                        <input
                          aria-readonly="true"
                          type="text"
                          name="qty"
                          class="box-border h-6 w-6 text-center border text-xl"
                          :id="'qty-' + paket.id"
                          :value="valueQty(paket)"
                        />
                        <button
                          type="button"
                          @click="plusQty(paket)"
                          class="text-3xl"
                        >
                          +
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="flex flex-col gap-4 overflow-y-auto h-80 text-center text-lg font-bold"
                v-else
              >
                Please select date first
              </div>
              <div class="grid grid-cols-5 gap-2 my-2">
                <div class="col-start-4 col-span-2">
                  <button
                    class="bg-yellow text-black w-full font-extrabold py-2 px-4 rounded-md font-xl text-xl"
                    type="button"
                    @click="setPackage"
                  >
                    BOOK
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="grid items-center" v-else>
          <div class="mx-auto mt-10">
            <img :src="iconTrip" :alt="textLabel" class="h-40 m-auto" />
            <span class="font-bold text-xl text-center" v-if="idWilayah == ''"
              >Select city & adventure type</span
            >
            <span class="font-bold text-xl text-center" v-else
              >Select other city or adventure type</span
            >
          </div>
        </div>
      </div>
    </div>
    <Footer />
    <modal-toko-rental ref="modalTokoRental"></modal-toko-rental>
  </div>
</template>

<script>
import Header from "../components/Header";
import Footer from "../components/Footer";
import DatePicker from "../components/DatePicker";
import CardDock from "../components/CardDock";
import { LMap, LTileLayer, LMarker } from "vue2-leaflet";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  name: "DetailRental",
  components: {
    Header,
    Footer,
    DatePicker,
    CardDock,
    LMap,
    LTileLayer,
    LMarker,
    ModalTokoRental: () => import("@/components/layout/ModalTokoRental.vue"),
    VueSlickCarousel,
  },
  data() {
    var now = new Date();
    return {
      date: new Date(),
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      mapOptions: {
        zoomSnap: 0.5,
      },
      bulantahun: "",
      activeTab: 0,
      idWilayah: "",
      selectedDock: {},
      totalSweep: 0,
      selectedToko: {},
      dataKalender: [],
      listKota: [],
      listDock: [],
      listToko: [],
      listPaket: [],
      minHour: new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate(),
        "9",
        "0"
      ),
      maxHour: new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate(),
        "17",
        "0"
      ),
      formData: {
        jam_awal: new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate(),
          "9",
          "0"
        ),
        jam_akhir: new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate(),
          "10",
          "0"
        ),
        bookingDate: null,
        selectedDock: {},
        toko: {},
      },
      attrs: [],
      disabledDates: [],
      settings: {
        arrows: true,
        dots: false,
        dotsClass: "custom-slick-dots",
        infinite: false,
        autoplay: false,
        swipeToSlide: true,
      },
    };
  },
  computed: {
    textLabel() {
      let str = "";
      switch (this.$route.params.slug) {
        case "wateradventure":
          str = "Watersport";
          break;
        case "landadventure":
          str = "Land Adventure";
          break;
        case "skyadventure":
          str = "Sky Adventure";
          break;

        default:
          str = "Watersport";
          break;
      }
      return str;
    },
    minDate() {
      var date = new Date();
      return date;
    },
    calAttr() {
      return this.attrs;
    },
    calDisable() {
      return this.disabledDates;
    },
    iconTrip() {
      let def = require("@/assets/icons/icon-water-advanture.svg");
      switch (this.$route.params.slug) {
        case "wateradventure":
          def = require("@/assets/icons/icon-water-advanture.svg");
          break;
        case "landadventure":
          def = require("@/assets/icons/icon-land-advanture.svg");
          break;
        case "skyadventure":
          def = require("@/assets/icons/icon-sky-advanture.svg");
          break;
        default:
          def = require("@/assets/icons/icon-water-advanture.svg");
          break;
      }
      return def;
    },
  },
  created() {
    this.loadCity();
    var now = this.$moment();
    this.formData.bulantahun = now.format("YYYY-MM-DD");
  },
  methods: {
    dockAfterChange(index) {
      var dock = this.listDock[index];
      this.changeDock(dock);
    },
    onMonthChange(page) {
      // var mt = page.month.toString();
      // if (page.month < 10) {
      //     mt = '0' + mt;
      // }
      // this.formData.bulantahun = page.year.toString() + mt;
      // if (this.listToko.length > 0) {
      //     this.loadCalendar();
      // }
    },
    async changeDock(dock) {
      this.formData.selectedDock = dock;
      this.listPaket = [];
      this.formData.bookingDate = null;
      await this.loadTokoRental();
    },
    setPackage() {
      var booked = [];
      for (const key in this.listPaket) {
        if (Object.hasOwnProperty.call(this.listPaket, key)) {
          const element = this.listPaket[key];
          if (element.qty > 0) {
            booked.push(element);
          }
        }
      }
      if (booked.length <= 0) {
        this.$toasted.global.error("Please select package first");
        return;
      }
      this.$store.commit("setPackageList", booked);
      this.$store.commit("setBookRental", this.formData);
      this.$router.push({ name: "CheckoutRental" });
    },
    onInputChange(value) {
      var st = this.$moment(this.formData.jam_awal);
      // if(st.format("mm") != '00'){
      //     this.formData.jam_awal = st.toString();
      // }
      var st = this.$moment(this.formData.jam_awal);
      var en = this.$moment(this.formData.jam_akhir);
      if (st.isSameOrAfter(en)) {
        this.formData.jam_akhir = this.$moment(this.formData.jam_awal)
          .add(1, "hours")
          .toString();
      }
      // this.$refs.jamStartRef.blur();
      this.listPaket = [];
      // this.formData.bookingDate = null;
      this.onDayClick();
      if (this.formData.bookingDate != null) {
        this.loadCalendar();
      }
    },
    onInputChangeEnd(value) {
      var st = this.$moment(this.formData.jam_awal);
      var en = this.$moment(this.formData.jam_akhir);
      if (st.isSameOrAfter(en)) {
        this.formData.jam_awal = this.$moment(this.formData.jam_akhir)
          .subtract(1, "hours")
          .toString();
      }
      this.listPaket = [];
      // this.formData.bookingDate = null;
      this.onDayClick();
      if (this.formData.bookingDate != null) {
        this.loadCalendar();
      }
    },
    onDayClick() {
      if (
        this.formData.bookingDate != "" &&
        this.formData.bookingDate != null
      ) {
        let valid = true;
        let bookDate = this.$moment(this.formData.bookingDate);
        let curDate = this.$moment();
        var st = this.$moment(this.formData.jam_awal);
        var en = this.$moment(this.formData.jam_akhir);
        let openTime = this.$moment("08:00:00", "hh:mm:ss");
        let closeTime = this.$moment("18:00:00", "hh:mm:ss");

        if (openTime.isAfter(st, "hour")) {
          this.$toasted.global.error(
            "Starting time available at 08:00, please change your starting time"
          );
          valid = false;
          this.listPaket = [];
          return;
        }
        if (en.isAfter(closeTime, "hour")) {
          this.$toasted.global.error(
            "Ending time available at 18:00, please change your ending time"
          );
          valid = false;
          this.listPaket = [];
          return;
        }

        if (bookDate.isSame(curDate, "date")) {
          // this.$toasted.global.error("Same day");
          var dur = st.diff(curDate, "hours", true);
          if (curDate.isSameOrAfter(st, "hour")) {
            this.$toasted.global.error(
              "Starting time passed, please change your starting time"
            );
            valid = false;
            this.listPaket = [];
            return;
          }
          if (
            dur < 2 &&
            this.selectedToko.id != this.$store.state.user.id_user
          ) {
            this.$toasted.global.error(
              "Operator must be prepare at least 2 hours before unit used, please change your starting time"
            );
            valid = false;
            this.listPaket = [];
            return;
          }
          // valid = false;
          // return;
        } else {
        }
        if (valid) {
          this.loadCalendar();
        }
      }
    },
    minusQty(det) {
      if (det?.qty === null) {
        det.qty = 0;
      } else {
        if (det.qty > 0) {
          det.qty--;
        } else {
          det.qty = 0;
        }
      }
    },
    plusQty(det) {
      if (det?.qty === null) {
        det.qty = 0;
      } else {
        // det.qty++;
        let ttlstok = 0;
        for (const x in this.listPaket) {
          if (Object.hasOwnProperty.call(this.listPaket, x)) {
            const el = this.listPaket[x];
            ttlstok += parseInt(el.qty, 10);
          }
        }
        // var usesweep = this.totalSweep[`sweeperno${ttlstok}`];
        // ttlstok += parseInt(usesweep,10);

        // let curData = {};
        // for (const xx in this.dataKalender) {
        //     if (Object.hasOwnProperty.call(this.dataKalender, xx)) {
        //         const elx = this.dataKalender[xx];
        //         const dt = this.$moment(elx.tanggal);
        //         const curDate = this.$moment(this.formData.bookingDate);
        //         if(dt.isSame(curDate, 'date')){
        //             curData = elx;
        //         }
        //     }
        // }
        // if(Object.keys(curData) != 0){
        //     ttlstok -= parseInt(curData.totalsweep,10);
        // }

        if (parseInt(det.stok, 10) > ttlstok) {
          det.qty++;
        } else {
          // det.qty--;
          this.$toasted.global.error(
            "Booked unit amount exceeding available unit for sweeper"
          );
        }
      }
    },
    valueQty(det) {
      return det?.qty || 0;
    },
    async loadCity() {
      try {
        this.$store.commit("setOverlayLoading", true);
        var bodyFormData = new FormData();
        bodyFormData.append("txtLat", "");
        bodyFormData.append("txtLong", "");
        bodyFormData.append("txtLimit", "50");
        bodyFormData.append("txtFilter", "a");
        bodyFormData.append("txtJenis", "r");

        var response = await this.$http.post(
          "https://tripwe.id/ic/jetski-android-new/list_wilayah_terdekat_v19.php",
          bodyFormData
        );
        this.listKota = response.data;
        if (this.listKota.length > 0) {
          // this.idWilayah = this.listKota[0].id_wilayah;
          if (
            this.$store.state.idWilayah != "" &&
            this.$store.state.idWilayah != null
          ) {
            // this.idWilayah = this.$store.state.idWilayah;
            // this.handleCityChange();
            let selectedWilayah = this.listKota.filter(
              (e) => e.id_wilayah == this.$store.state.idWilayah
            );

            if (selectedWilayah.length > 0) {
              this.idWilayah = selectedWilayah[0].id_wilayah;
              this.handleCityChange();
            }
          }
        }
        // this.loadDock();
      } catch (error) {
        this.$toasted.global.error("Failed load city");
      } finally {
        this.$store.commit("setOverlayLoading", false);
      }
    },
    handleCityChange() {
      this.$store.commit("setIdWilayah", this.idWilayah);
      this.listPaket = [];
      this.formData.bookingDate = null;
      this.loadDock();
    },
    async loadDock() {
      if (this.idWilayah == null || this.idWilayah == "") {
        this.listDock = [];
        this.formData.selectedDock = {};
        return;
      }

      this.$store.commit("setOverlayLoading", true);
      try {
        var bodyFormData = new FormData();
        bodyFormData.append("txtStatus", 0);
        bodyFormData.append("txtIdWilayah", this.idWilayah);

        var response = await this.$http.post(
          "https://tripwe.id/ic/jetski-android-new/list_dermaga_status.php",
          bodyFormData
        );
        if (response.data != "null") {
          this.listDock = response.data;
          this.formData.selectedDock = this.listDock[0];
          await this.loadTokoRental();
        }
      } catch (error) {
        this.listDock = [];
        this.$toasted.global.error("Failed to load dock");
      } finally {
        this.$store.commit("setOverlayLoading", false);
      }
    },
    async loadTokoRental() {
      this.$store.commit("setOverlayLoading", true);
      try {
        var response = await this.$http.get(
          "https://tripwe.id/apptripwe/api/gettokojetski/" +
            this.formData.selectedDock.id_dermaga
        );
        this.listToko = response.data.data;
      } catch (error) {
        this.$toasted.global.error("Failed load data store");
      } finally {
        this.$store.commit("setOverlayLoading", false);
        if (this.listToko.length > 1) {
          this.openModalToko();
        } else if (this.listToko.length == 1) {
          this.selectedToko = this.listToko[0];
          this.formData.toko = this.listToko[0];
        }
      }
    },
    async openModalToko() {
      var selToko = await this.$refs.modalTokoRental.open({
        listToko: this.listToko,
      });
      this.selectedToko = selToko;
      this.formData.toko = selToko;
      // this.loadCalendar();
    },
    async loadCalendar() {
      this.$store.commit("setOverlayLoading", true);
      try {
        const response = await this.$http.post(
          `${this.$apiTripweWeb}/calendars/rental/jetski`,
          {
            start_date:
              this.$moment(this.formData.bookingDate).format("YYYY-MM-DD") +
              " " +
              this.$moment(this.formData.jam_awal).format("HH:mm:00"),
            end_date:
              this.$moment(this.formData.bookingDate).format("YYYY-MM-DD") +
              " " +
              this.$moment(this.formData.jam_akhir).format("HH:mm:00"),
            store_id: this.selectedToko.id,
          }
        );
        if (response.data.code == "20000") {
          if (response.data.data.is_open != true) {
            this.formData.bookingDate = null;
            throw "Merchant is closed";
          }
          if (response.data.data.is_open == true) {
            await this.loadPaket();
          }
        }
      } catch (error) {
        if (error.response.status == "500") {
          this.$toasted.global.error("No available unit");
        } else {
          this.$toasted.global.error(error);
        }
      } finally {
        this.$store.commit("setOverlayLoading", false);
      }
      this.$store.commit("setOverlayLoading", false);
      return;
    },
    async loadPaket() {
      this.$store.commit("setOverlayLoading", true);
      try {
        var tglAwal =
          this.$moment(this.formData.bookingDate).format("YYYYMMDD") +
          "" +
          this.$moment(this.formData.jam_awal).format("HHmm");
        var tglAkhir =
          this.$moment(this.formData.bookingDate).format("YYYYMMDD") +
          "" +
          this.$moment(this.formData.jam_akhir).format("HHmm");
        var res = await this.$http.post(
          // "https://trip.saebo.id/TWPRDV2/api/ListOrderV3",
          `${this.$apiTripweWeb}/package/rental`,
          {
            id: "UATTWRNTLV2",
            toko: this.formData.toko?.id,
            dermaga: this.formData.selectedDock?.id_dermaga,
            tglawal: tglAwal,
            tglakhir: tglAkhir,
            limit: "",
          }
        );
        this.listPaket = res.data;
      } catch (error) {
        this.$toasted.global.error(error);
      } finally {
        this.$store.commit("setOverlayLoading", false);
      }
      return;
    },
  },
};
</script>
