<template>
  <div class="m-2">
    <div
      class="w-full pb-2"
      :class="{ 'border-b-4 border-yellow': checkSelected() }"
    >
      <div
        class="block rounded-2xl shadow-lg bg-white max-w-sm text-center cursor-pointer"
      >
        <div
          class="py-3 px-6 border-b border-gray-300 bg-yellow text-black font-extrabold rounded-tr-2xl rounded-tl-2xl"
        >
          {{ $props.detail.nama_dermaga }}
        </div>
        <div class="w-full p-4 sm:min-w-max">
          <img
            :src="$props.detail.foto"
            :alt="$props.detail.nama_dermaga"
            class="object-cover rounded-2xl min-w-full w-80 h-28"
          />
        </div>
        <div class="pb-4 px-4 text-left flex justify-between">
          <div>
            <div class="font-bold">{{ $props.detail.nama_dermaga }}</div>
            <div class="text-xs">{{ $props.detail.alamat }}</div>
          </div>
          <div>
            <font-awesome-icon
              icon="fa-solid fa-map-marker-alt"
              :style="{ color: 'black', fontSize: '30px' }"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    detail: {
      type: Object,
      required: true,
    },
    selectedDock: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      activeTab: 1,
    };
  },
  methods: {
    checkSelected() {
      return (
        this.$props.selectedDock.id_dermaga === this.$props.detail.id_dermaga
      );
    },
  },
};
</script>
